import React from "react";
import styled from "styled-components";
import { WideContainer } from "./container";
import { baseHeading } from "./typography";
import BackgroundImage from "gatsby-background-image";

/**
 * Header
 */

export const Header: React.FC<{ backgroundImage: any }> = (props) => {
  const { backgroundImage, children } = props;

  return (
    <BackgroundImage
      fluid={[
        "linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))",
        backgroundImage,
      ]}
      fadeIn={false}
    >
      <HeaderWrapper>
        <WideContainer style={{ position: "relative", top: "35%" }}>
          <TopHeading>{children}</TopHeading>
        </WideContainer>
      </HeaderWrapper>
    </BackgroundImage>
  );
};

/**
 * Header wrapper
 */

const HeaderWrapper = styled.div`
  height: 25vh;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 30vh;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 35vh;
  }
`;

/**
 * Base top heading
 */

export const BaseTopHeading = styled.h1`
  ${baseHeading}

  color: #fff;
  margin: 0;

  &::before {
    content: "";
    display: block;
    width: 3em;
    border-bottom: 2px solid #00a88e;
    margin-bottom: 0.5em;
  }
`;

/**
 * Top heading
 */

const TopHeading = styled(BaseTopHeading)`
  font-size: 30px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 50px;
  }
`;
